import * as React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

const BlogPage = ({ data }) => {
    return (
    <Layout pageTitle="My Blog Posts">
    {
      data.allMdx.nodes.map((node) => (
        <article key={node.id}>
          <h2>{node.frontmatter.title}</h2>
          <p>Posted: {node.frontmatter.datePublished}</p>
          <p>Author: {node.frontmatter.author}</p>
          <p>Name: {node.frontmatter.name}</p>

            <MDXRenderer>
                {node.body}
            </MDXRenderer>
        </article>
      ))
    }
  </Layout>
    )
  }

  export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___datePublished, order: DESC}) {
      nodes {
        frontmatter {
          datePublished(formatString: "MMMM D, YYYY")
          name
          title
        }
        id
        body
      }
    }
  }`

export default BlogPage